/** @module auth */

/**
 * Initializes authentication (login / logout) functionalities.
 *
 * @return {Promise<void>} - Resolves immediately
 */
export async function auth() {
    const logoutButtons = document.querySelectorAll('[data-logout]');

    if (logoutButtons.length >= 1) {
        const { initAuth } = await import(/* webpackMode: "lazy" */ './inc/auth.async.js');

        initAuth(logoutButtons);
    }
}
