/** @module navigation */

import { liveEvents } from '../../../../../assets/js/application/index.js';
import $ from 'jquery';

/**
 * Initializes the Off-Canvas component
 * @returns {Promise<void>} - Resolves once the component is fully initialized.
 */
export async function initOffCanvas() {
    const events = await liveEvents(),
        body = document.body,
        offCanvasSelector = '[data-off-canvas]',
        offCanvasContainers = document.querySelectorAll(offCanvasSelector),
        toggleButtons = document.querySelectorAll('[data-off-canvas-toggle]'),
        previouslyFocusedElements = new WeakMap(),
        isInCanvas = () => {
            const breakpoint = 1024, // check _off-canvas.scss && .breakpointsrc.json
                html = document.querySelector('html');

            // workaround: use html.clientWidth instead of Foundation.MediaQuery.atLeast(breakpoint) because it doesn't work properly
            return html.clientWidth >= breakpoint;
        },
        setSiteInert = (offCanvas, inert = true) => {
            let parent = offCanvas.parentNode;

            while (parent && parent !== body) {
                Array.from(parent.parentNode.children)
                    .filter((child) => child !== parent)
                    .forEach((el) => {
                        if (inert) {
                            el.setAttribute('inert', true);
                        } else {
                            el.removeAttribute('inert');
                        }
                    });

                parent = parent.parentNode;
            }
        },
        setOffCanvasInert = (offCanvas, inert = true) => {
            if (inert) {
                offCanvas.setAttribute('inert', true);
            } else {
                offCanvas.removeAttribute('inert');
            }
        },
        onOffCanvasStateToggle = (offCanvas) => {
            if (isInCanvas(offCanvas)) {
                return;
            }

            const isOpen = offCanvas.dataset.isOpen === 'true',
                currentlyOpenOffCanvas = (body.dataset.openOffCanvas || '').split(',').filter(Boolean);

            // add an indicator-class for each open off-canvas
            body.classList.toggle(`is-off-canvas-${offCanvas.id}-open`, isOpen);

            offCanvas.classList.toggle('is-open', isOpen);

            toggleButtons.forEach((button) => {
                if (button.dataset.offCanvasToggle === offCanvas.id) {
                    button.classList.toggle('is-active', isOpen);
                    button.setAttribute('aria-expanded', String(isOpen));
                } else {
                    button.classList.toggle('is-disabled', isOpen);
                }
            });

            if (isOpen) {
                const focusableElement = offCanvas.querySelector(
                    'input, textearea, select, a[href], button:not([disabled]), *[tabindex]',
                );

                previouslyFocusedElements.set(offCanvas, document.activeElement);
                currentlyOpenOffCanvas.push(offCanvas.id);

                setOffCanvasInert(offCanvas, false);

                requestAnimationFrame(() => {
                    if (focusableElement) {
                        focusableElement.focus({ preventScroll: true });
                    } else {
                        offCanvas.setAttribute('tabindex', '-1');
                        offCanvas.focus({ preventScroll: true });
                        offCanvas.removeAttribute('tabindex');
                    }
                });
            } else {
                let activeElement = previouslyFocusedElements.get(offCanvas),
                    i = currentlyOpenOffCanvas.indexOf(offCanvas.id);

                previouslyFocusedElements.delete(offCanvas);

                if (i !== -1) {
                    currentlyOpenOffCanvas.splice(i, 1);
                }

                setOffCanvasInert(offCanvas, true);

                if (activeElement) {
                    requestAnimationFrame(() => {
                        activeElement.focus();
                    });
                }
            }

            body.dataset.openOffCanvas = currentlyOpenOffCanvas.join(',');

            setSiteInert(offCanvas, isOpen);
        },
        openingObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-is-open') {
                    onOffCanvasStateToggle(mutation.target);
                }
            });

            // as long as at least one off-canvas is open, add a generic indicator-class
            body.classList.toggle('is-off-canvas-open', /is-off-canvas-(.+?)-open/.test(body.className));
        }),
        openingObserverOptions = {
            attributes: true,
            attributeFilter: ['data-is-open'], // do not use data-open, as it is occupied by Foundation
        };

    offCanvasContainers.forEach((offCanvas) => {
        openingObserver.observe(offCanvas, openingObserverOptions);
    });

    function toggleOffCanvas(offCanvas, forceState = null) {
        if (typeof offCanvas === 'string') {
            offCanvas = document.getElementById(offCanvas);
        }

        if (offCanvas && isInCanvas(offCanvas) === false) {
            offCanvas.dataset.isOpen =
                forceState !== null ? forceState : offCanvas.dataset.isOpen === 'true' ? 'false' : 'true';
        }
    }

    events.addEventListener(
        '[data-off-canvas-toggle]',
        'click',
        (event, selector) => {
            const toggleButton = event.target.closest(selector);

            toggleOffCanvas(toggleButton.dataset.offCanvasToggle || toggleButton.closest('[data-off-canvas]'));
        },
        { passive: true },
    );

    events.addEventListener(
        document,
        'keydown',
        (event) => {
            if (event.key === 'Escape') {
                const currentlyOpenOffCanvas = (body.dataset.openOffCanvas || '').split(',').filter(Boolean);

                toggleOffCanvas(currentlyOpenOffCanvas.pop(), false);
            }
        },
        { passive: true },
    );

    $(window).on('changed.zf.mediaquery', () => {
        offCanvasContainers.forEach((offCanvas) => {
            if (isInCanvas(offCanvas)) {
                setOffCanvasInert(offCanvas, false);
                setSiteInert(offCanvas, false);
            } else {
                const isOpen = offCanvas.dataset.isOpen === 'true';

                setOffCanvasInert(offCanvas, !isOpen);
                setSiteInert(offCanvas, isOpen);
            }
        });
    });
}
