/** @module guildInput */

/**
 * Initializes guild-inputs functionalities.
 *
 * @return {Promise<void>} - Resolves immediately
 */
export async function guildInputs() {
    const forms = document.querySelectorAll('form[data-guild-inputs-form]');

    if (forms.length >= 1) {
        const { initGuildInputs } = await import(/* webpackMode: "lazy" */ './inc/guildInputs.async.js');

        initGuildInputs(forms);
    }
}
