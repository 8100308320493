export async function companySearch() {
    const searchForm = document.querySelector('[data-company-search-form]'),
        inputField = document.querySelector('[data-company-search-form-input]'),
        updateFormAction = () => {
            if (inputField && searchForm) {
                const inputValue = inputField.value.trim();

                if (inputValue !== '') {
                    searchForm.action = `${searchForm.dataset.companySearchForm}${inputValue}/`;
                }
            }
        };

    if (searchForm) {
        searchForm.addEventListener('submit', (e) => {
            const inputValue = inputField.value.trim();

            if (inputValue === '') {
                e.preventDefault();
            } else {
                updateFormAction();
            }
        });
    }
}
